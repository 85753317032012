/* eslint-disable camelcase */
import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import MainLayout from '../layouts/MainLayout';
import Hero from '../layouts/Hero';
import Container from '../components/Container';
import Image from '../components/Image';
import SEO from '../components/SEO';
import Slider from '../components/Slider/index';

import './ArtistItem.styl';

const ArtistItem = ({
    data,
    location,
    pageContext: { locale, translations },
}) => {
    const { artist, hero } = data;

    const {
        artistCollection,
        copyright,
        descriptif_artiste,
        fields,
        logoP,
        meta_description,
        site_artiste,
        titreP,
    } = artist;
    const { avatarSrc, logoSrc } = fields;

    const artistName = titreP.split(' ');

    return (
        <MainLayout
            locale={locale}
            translationsPaths={translations}
            neverTransparent
        >
            <SEO
                title={titreP}
                location={location}
                translationsPaths={translations}
                description={meta_description}
                pageType='artist'
            />

            <Hero
                image={hero.childImageSharp.gatsbyImageData}
                title={titreP}
                titleNoTranslation
                // text1='pages.partnership.hero.p1'
                // text2='pages.partnership.hero.p2'
            />

            <div className='header-spacer' />
            <main id='content' className='artist-item-page'>
                <section className='group-intro'>
                    <Container>
                        <div className='artist-top'>
                            <div className='artist-bloc-left'>
                                <div className='avatar'>
                                    <Image
                                        alt={`avatar-${titreP}`}
                                        image={
                                            avatarSrc.childImageSharp
                                                .gatsbyImageData
                                        }
                                        objectFit='cover'
                                        style={{
                                            flexGrow: 1,
                                            height: '100%',
                                        }}
                                    />
                                </div>
                                <div className='artist-name'>
                                    <div className='name'>{artistName[0]}</div>
                                    <div className='lastname'>
                                        {artistName[1]}
                                    </div>
                                </div>
                            </div>

                            <div className='artist-bloc-right'>
                                <div className='artist-description'>
                                    {descriptif_artiste.map((text, i) => (
                                        <p
                                            key={`artist-description-${i}`}
                                            // eslint-disable-next-line react/no-danger
                                            dangerouslySetInnerHTML={{
                                                __html: text,
                                            }}
                                        />
                                    ))}
                                </div>

                                {logoP !== '' && (
                                    <div className='signature'>
                                        <Image
                                            alt={`signature-${titreP}`}
                                            image={
                                                logoSrc.childImageSharp
                                                    .gatsbyImageData
                                            }
                                            objectFit='cover'
                                            style={{
                                                flexGrow: 1,
                                                height: '100%',
                                            }}
                                        />
                                    </div>
                                )}

                                <a
                                    href={site_artiste}
                                    className='artist-link'
                                    target='_blank'
                                    rel='noreferrer noopener'
                                >
                                    {copyright}
                                </a>
                            </div>
                        </div>

                        <div className='artist-collection'>
                            <FormattedMessage
                                tagName='h3'
                                id='pages.partnership.collection'
                            />
                            <div className='gallery'>
                                <Slider
                                    data={artistCollection}
                                    slidesToShow={4}
                                />
                            </div>
                        </div>
                    </Container>
                </section>
            </main>
        </MainLayout>
    );
};

ArtistItem.propTypes = {
    data: PropTypes.shape({
        artist: PropTypes.object.isRequired,
        hero: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
    pageContext: PropTypes.shape({
        artistPath: PropTypes.string.isRequired,
        locale: PropTypes.string.isRequired,
        translations: PropTypes.array.isRequired,
    }).isRequired,
};

export default ArtistItem;

export const query = graphql`
    query ($artistPath: String!, $cover: String!) {
        hero: file(
            sourceInstanceName: { eq: "partnership" }
            relativePath: { eq: $cover }
        ) {
            ...BgImagesArtist
        }
        artist: products(fields: { path: { eq: $artistPath } }) {
            ...ArtistDetail
        }
    }
`;
